import { Component, ContentChild, Input } from '@angular/core';
import {
  FormControlDirective,
  FormControlName,
  NgControl,
  NgModel,
} from '@angular/forms';
import { FormErrorMessageCatalog } from './form-error-messages';
import { CommonModule } from '@angular/common';
import { FormErrorMessagesComponent } from './form-error-messages.component';

@Component({
  selector: '[uiFieldWithError]',
  template: `
    <ng-content></ng-content>
    @if (shouldShowError) {
      <div
        uiFormErrors
        class="ds-text"
        [customMessages]="customErrorMessages"
        [errors]="errors"
      ></div>
    }
  `,
  standalone: true,
  imports: [CommonModule, FormErrorMessagesComponent],
  host: {
    class: 'ds-field',
  },
  // Note: ChangeDetectionStrategy.OnPush is NOT used on purpose.
  // It's because this component is designed to show errors if the corresponding input element is invalid && (touched || dirty)
  // So we have to react on changes of not only validation status but also touched/dirty state.
  // Under the OnPush strategy, we can tell validation status changes via AbstractControlDirective.statusChanges but not for touched/dirty state.
  // So, say we have `<input type="text" required>` with no initial value (i.e. "invalid" from the beginning), even if user interacts with the input element and it gets touched/dirty, error messages won't appear under the OnPush.
  // OTOH, if it's "Default" (not OnPush), user interaction will trigger a changeDetection and so we can react on the change. Note that parent/ancestor components can be OnPush because components with elements which triggers Event (and the event is listened via Angular) will always be checked.
})
export class FormFieldWithErrorsComponent {
  // capture formControl,formControlName or ngModel
  @ContentChild(FormControlDirective, { static: true })
  formControl?: FormControlDirective;
  @ContentChild(FormControlName, { static: true })
  controlName?: FormControlName;
  @ContentChild(NgModel, { static: true }) ngModel?: NgModel;

  @Input() customErrorMessages?: FormErrorMessageCatalog;

  get ngControl(): NgControl | null {
    return this.formControl ?? this.controlName ?? this.ngModel ?? null;
  }

  get errors() {
    return this.ngControl?.errors ?? null;
  }

  get shouldShowError() {
    return (
      this.ngControl?.invalid &&
      (this.ngControl?.touched || this.ngControl?.dirty)
    );
  }
}
