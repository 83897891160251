import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { TextContentPipeModule } from '@soracom/shared-ng/util-common';
import { FormFieldWithErrorsComponent } from './field-with-error.component';
import { FormErrorMessagesProviderDirective } from './form-error-message-provider.directive';
import {
  DEFAULT_FORM_ERROR_MESSAGES,
  FormErrorMessageCatalog,
  FORM_ERROR_MESSAGE_CATALOGS,
} from './form-error-messages';
import { FormErrorMessagesComponent } from './form-error-messages.component';

@NgModule({
  imports: [
    CommonModule,
    TextContentPipeModule,
    FormErrorMessagesProviderDirective,
    FormErrorMessagesComponent,
    FormFieldWithErrorsComponent,
  ],
  exports: [
    FormErrorMessagesProviderDirective,
    FormErrorMessagesComponent,
    FormFieldWithErrorsComponent,
  ],
})
export class FormErrorModule {
  static forRoot(
    defaultFormErrorMessages?: FormErrorMessageCatalog
  ): ModuleWithProviders<FormErrorModule> {
    return {
      ngModule: FormErrorModule,
      providers: [
        {
          provide: FORM_ERROR_MESSAGE_CATALOGS,
          useValue: defaultFormErrorMessages ?? DEFAULT_FORM_ERROR_MESSAGES,
          multi: true,
        },
      ],
    };
  }
}
