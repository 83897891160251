import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Input,
  Optional,
} from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import {
  TextContent,
  TextContentPipeModule,
} from '@soracom/shared-ng/util-common';
import {
  FormErrorMessageCatalog,
  FormErrorMessageDef,
  FORM_ERROR_MESSAGE_CATALOGS,
  getErrorTextContent,
} from './form-error-messages';

@Component({
  selector: '[uiFormErrors]',
  template: `
    @for (e of errorList; track e) {
      <div
        class="ds-text --alert"
        [innerHtml]="getErrorMessage(e) | text"
      ></div>
    }
  `,
  standalone: true,
  imports: [TextContentPipeModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormErrorMessagesComponent {
  @Input() customMessages?: FormErrorMessageCatalog;

  @Input() errors: ValidationErrors | null = null;

  constructor(
    @Inject(FORM_ERROR_MESSAGE_CATALOGS)
    @Optional()
    private messagesList?: ReadonlyArray<FormErrorMessageCatalog>,
  ) {}

  get errorList() {
    return Object.keys(this.errors ?? {}).map((key) => ({
      key,
      param: this.errors?.[key],
    }));
  }

  getErrorMessage(error: { key: string; param?: any }): TextContent {
    const msgDef = this.findErrorMessageContent(error.key);
    if (!msgDef) {
      console.log(
        `[Warning] Error message definition for ${error.key} is missing`,
      );
      return '';
    }
    return getErrorTextContent(msgDef, error.param);
  }

  findErrorMessageContent(key: string): FormErrorMessageDef | null {
    if (this.customMessages?.[key]) {
      return this.customMessages[key];
    }

    for (const messageMap of this.messagesList ?? []) {
      if (messageMap?.[key]) {
        return messageMap[key];
      }
    }
    return null;
  }
}
