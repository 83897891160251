import { Directive, forwardRef, inject, Input } from '@angular/core';
import {
  FormErrorMessageCatalog,
  FORM_ERROR_MESSAGE_CATALOGS,
} from './form-error-messages';

/**
 * Directive to provide form error messages via DI
 * so as to overrode error messages for descendants.
 */
@Directive({
  selector: '[uiFormErrorMessages]',
  providers: [
    {
      provide: FORM_ERROR_MESSAGE_CATALOGS,
      useFactory: () =>
        inject<FormErrorMessagesProviderDirective>(
          forwardRef(() => FormErrorMessagesProviderDirective),
        ).formErrorMessages,
      multi: true,
    },
  ],
  standalone: true,
})
export class FormErrorMessagesProviderDirective {
  formErrorMessages: FormErrorMessageCatalog = {};

  // update messages without changing object reference
  @Input() set uiFormErrorMessages(msg: FormErrorMessageCatalog) {
    Object.keys(msg).forEach((key) => {
      this.formErrorMessages[key] = msg[key];
    });
  }
}
