import { InjectionToken } from '@angular/core';
import { isTranslatable, TextContent } from '@soracom/shared-ng/util-common';

/**
 * Error Message definition for an error
 * For functions, the parameter `error` will returned value from corresponding Validator (@angular/form) functions
 */
export type FormErrorMessageDef = TextContent | ((error: any) => TextContent);

/**
 * Form Error Message Definitions
 * `ErrorKey` is keys used in return value object for Validator (@angular/form) functions
 */
export interface FormErrorMessageCatalog {
  [ErrorKey: string]: FormErrorMessageDef;
}

/**
 * get actual `TextContent` of message for an error
 * @param m:
 * @param error error value for a error key, reported by ValidatorFn via formControl/ngModel
 * @returns TextContent populated with error value as Function parameter and translatable parameter
 */
export function getErrorTextContent(
  m: FormErrorMessageDef,
  error: any
): TextContent {
  const t = typeof m === 'function' ? m(error) : m;
  return isTranslatable(t)
    ? { id: t.id, parameters: { ...error, ...t.parameters } }
    : t;
}

// expected to be a `multi` provider so as to provide and override definitions for each case like root, a page and a form
export const FORM_ERROR_MESSAGE_CATALOGS = new InjectionToken<
  ReadonlyArray<FormErrorMessageCatalog>
>('FORM_ERROR_MESSAGES');

export const DEFAULT_FORM_ERROR_MESSAGES = {
  min: (arg: { min: number; actual: number }) => `Min: ${arg.min}`,
  max: (arg: { max: number; actual: number }) => `Max: ${arg.max}`,
  required: 'Required',
  email: 'Invalid email address',
  minlength: (arg: { requiredLength: number; actualLength: number }) =>
    `Min length: ${arg.requiredLength}`,
  maxlength: (arg: { requiredLength: number; actualLength: number }) =>
    `Max length: ${arg.requiredLength}`,
  pattern: 'Invalid format',
};
